// Metrics Driver configs
export const DRIVER_LOG_TO_CONSOLE = false;
export const CLIENT_METRICS_ENDPOINT = "/jwt/client_metrics";
export const SITE = "FondueReporting";
export const SERVICE_NAME = "FondueTelemetry";
export const BATCH_TIMEOUT_DURATION = 25000;

// Metric names
export const LATENCY = "Latency";
export const REPORT_ITEM_EDIT_TIME = "ReportItemEditTime";

// Page names
export const REPORTS_PAGE = "Reports"
export const CREATE_REPORT_PAGE = "CreateReport"
export const RUN_HISTORY_PAGE = "RunHistory"
export const EDIT_REPORT_PAGE = "EditReport"
export const RUN_REPORT_PAGE = "RunReport"
export const REPORT_ITEMS_PAGE = "ReportItems";
export const INVALID_PAGE = "InvalidPage";

// Functionality groups
export const QUERY_FILTER = "QueryFilter";
export const POLICY_DER_OUTPUT_COLUMNS = "PolicyDEROutputColumns";
export const ORGANIZATION_POLICY_DER_OUTPUT_COLUMNS = "OrganizationPolicyDEROutputColumns";
export const DER_ITEM_TYPE = "DERItemType";
export const WBR_ITEM_TYPE = "WBRItemType";
export const MANAGED_POLICIES_OPTION = "ManagedPoliciesOption";
export const ORGANIZATION_POLICIES_TYPE = "OrganizationPoliciesType";
export const QUERY_REGEX = "QueryRegex";
export const ADDITIONAL_ITEM_CALCULATIONS = "AdditionalItemCalculations";

// Filter types
export const ORGANIZATION_ID_FILTER = "Organization_ID_Filter";
export const EFFECT_FILTER = "Effect_Filter";
export const ACTION_FILTER = "Action_Filter";
export const RESOURCE_FILTER = "Resource_Filter";
export const CONDITION_FILTER = "Condition_Filter";
export const POLICY_PRINCIPLE_FILTER = "Policy_Principal_Filter";
export const SERVICE_PRINCIPAL_FILTER = "Service_Principal_Filter";
export const ACCOUNT_FILTER = "Account_Filter";

// Path patterns
export const PATH_EXACT_MATCH = new Map([
    ["/reports", REPORTS_PAGE],
    ["/reports/create", CREATE_REPORT_PAGE],
]);
export const PATH_PATTERN_MATCH = new Map([
    ["edit", EDIT_REPORT_PAGE],
    ["result", RUN_HISTORY_PAGE],
    ["runreport", RUN_REPORT_PAGE],
]);
export const PATH_REGEXP = /^\/reports\/[^/]+(\/(?<endSegment>.+))?$/;
