import { useEffect, useState, useCallback } from 'react';
import {useHistory, useParams} from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import {Report, ReportUpload, FondueApi} from '../../fondue-api/generated-src';
import { States } from "../../common/States";
import { ButtonDropdown, ButtonDropdownProps } from '@amzn/awsui-components-react';
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Input from "@amzn/awsui-components-react/polaris/input";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import {CollectionPreferencesProps} from "@amzn/awsui-components-react/polaris/collection-preferences";
import {useData} from '../DataContext';
import Select from "@amzn/awsui-components-react/polaris/select";

import FileUpload from "@amzn/awsui-components-react/polaris/file-upload";

import {
    Box,
    CollectionPreferences,
    FormField,
    Header,
    Table,
    TableProps,
    SpaceBetween,
    StatusIndicatorProps,
    Badge, Alert, ProgressBar, Textarea, TextContent
} from '@amzn/awsui-components-react/polaris';
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import * as constants from '../../common/constants';


export interface ReportUploadFileModalProps {
    setVisible: (x: boolean) => void;
    visible: boolean;
    preselectedResourceType?: string;
    addToken?: Function;
}

export default function ReportUploadFileModal({
        setVisible,
        visible,
        preselectedResourceType,
        addToken
        }: ReportUploadFileModalProps) {

    const { report_id }= useParams<{report_id: string}>();
    const FondueApi = FondueApiFactory();
    const [fileValue, setFileValue] = useState<readonly File[]>([]);
    interface SelectedOption {
               label?: string;
               value?: string;
            }

    const [selectedResourceType, setSelectedResourceType] = useState<SelectedOption>()
    const [uploadStatus, setUploadStatus] = useState<string>("")
    const [uploadStatusType, setUploadStatusType] = useState<StatusIndicatorProps.Type>("pending")
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState<boolean>(false)

    useEffect(() => {
        (async () => {
            if(preselectedResourceType){
                setSelectedResourceType({
                    "label":preselectedResourceType,
                    "value":preselectedResourceType
                })
            }

        })();
    }, []);


    async function getReportUploadPresignedUrl(uploadName: string, resourceType:string, counter: number): Promise<string>{
        await getMidwayJwtToken();
        if(counter < constants.API_RETRY_COUNT){
            let returnVal = 'Failed'
            await FondueApi.getReportUploadPresignedUrl(report_id,resourceType,uploadName)
                .then(async(response) => {
                    returnVal = response['data']['presigned_url']!;
                })
                .catch((e) => {
                    if(e.response.status==409){
                        setUploadStatusType("error")
                        setUploadStatus("Upload already exists, please delete existing upload first.")
                    }
                    else{
                        getReportUploadPresignedUrl(uploadName, resourceType, counter + 1).then((response)=>{
                            returnVal=response
                        });
                    }
                })
            return returnVal
        }
        return 'Failed'
    }

    async function validateReportUpload(uploadName: string, resourceType:string, counter: number){
            await getMidwayJwtToken();
            if(counter < constants.API_RETRY_COUNT){

                await FondueApi.validateReportUpload(report_id,resourceType,uploadName)
                    .then(async(response) => {
                        if(response.status === 200){
                            setUploadStatusType("success")
                            setUploadStatus("Uploaded file. You can close this window or upload another file by selecting a new file above.")
                            if(addToken){
                                addToken(uploadName,'csv')
                            }
                        }

                    })
                    .catch((e) => {
                        if(e.response.status==400){
                            setUploadStatusType("error")
                            setUploadStatus("Validation failed due to incorrectly formatted file.")
                        }
                        else{
                            validateReportUpload(uploadName, resourceType, counter + 1)
                        }
                    })
                return;
            }
            setUploadStatusType("error")
            // TODO: give more information about the failure
            setUploadStatus("Validation failed")
        }

    function uploadFile(){
        //TODO: pre-validation on UI to ensure the file is right type more quickly
        setUploadButtonDisabled(true)
        let file: File = fileValue[0]
        const uploadName = file.name
        const resourceType = preselectedResourceType ? preselectedResourceType : selectedResourceType!.value!
        setUploadStatusType("loading")
        setUploadStatus("Starting upload")
        let presignedUrl = getReportUploadPresignedUrl(uploadName, resourceType, 0)
        presignedUrl.then((value)=> {
            if(value != 'Failed'){
                setUploadStatusType("loading")
                setUploadStatus("Uploading file")

                putFile(file,value).then((putFileResult)=>{
                    if(putFileResult == 200){
                        setUploadStatusType("loading")
                        setUploadStatus("Validating file")
                        validateReportUpload(uploadName, resourceType, 0);
                    }
                });
            }
        })

    }

    const putFile = async (file: File, url: string) => {
        const headers = new Headers({
            "Content-Type": file.type,
            "Content-Length": file.size.toString()
        });
        const response = await fetch(url, {
            method: "PUT",
            headers,
            body: file
        })
        if(response.status != 200){
            setUploadStatusType("error")
            setUploadStatus("Failed to upload file.")
        }
        return response.status
    }

    //Add to this list as we support new resource types
    const resourceTypes=['account_id']
    const resourceTypeOptions = [
        { "label": 'account_id', "value": 'account_id' }
    ]

    function isUploadDisabled(){
        return uploadButtonDisabled || !selectedResourceType || fileValue.length==0
    }

    function uploadFileSelection(file){
        setFileValue(file)
        setUploadButtonDisabled(false)
        setUploadStatusType("pending")
        setUploadStatus("")
    }

    return(
        <Modal
          onDismiss={() => setVisible(false)}
          visible={visible}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={() => setVisible(false)}>Cancel</Button>
                <Button variant="primary" disabled={isUploadDisabled()} onClick={uploadFile}>Upload</Button>
              </SpaceBetween>
            </Box>
          }
          header="Upload file"
        >
          <FormField
            label="Resource data file"
            description="A file containing resource data used to filter DER report items"
          >
            <FileUpload
              onChange={({ detail }) => uploadFileSelection(detail.value)}
              value={fileValue}
              multiple={false}
              i18nStrings={{
                uploadButtonText: e =>
                  e ? "Choose files" : "Choose file",
                dropzoneText: e =>
                  e
                    ? "Drop files to upload"
                    : "Drop file to upload",
                removeFileAriaLabel: e =>
                  `Remove file ${e + 1}`,
                limitShowFewer: "Show fewer files",
                limitShowMore: "Show more files",
                errorIconAriaLabel: "Error"
              }}
              showFileLastModified
              showFileSize
              showFileThumbnail
              tokenLimit={3}
              constraintText="Must be a .CSV and must contain a single resource type."
            />
            <TextContent>
              <p>
                <b>Resource type</b>
              </p>
              <p>
                <small>Data type of the uploaded CSV file</small>
              </p>
            </TextContent>
            <Select
              selectedOption={selectedResourceType!}
              onChange={({ detail }) =>
                setSelectedResourceType(detail.selectedOption)
              }
              disabled={!(preselectedResourceType===undefined)}
              options={resourceTypeOptions}
              placeholder="Choose what type of data this csv contains"
            />
            {uploadStatus != "" &&
              <StatusIndicator type={uploadStatusType}>
                {uploadStatus}
              </StatusIndicator>
            }
          </FormField>
        </Modal>
    );
}
