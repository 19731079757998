import { useEffect, useState, useCallback } from 'react';
import {useHistory, useParams} from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import { getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import {Report, ReportUpload, FondueApi, RetrieveCFUrlRequest, RetrieveReportRequest} from '../../fondue-api/generated-src';
import { States } from "../../common/States";
import { ButtonDropdown, ButtonDropdownProps } from '@amzn/awsui-components-react';
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Input from "@amzn/awsui-components-react/polaris/input";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import {CollectionPreferencesProps} from "@amzn/awsui-components-react/polaris/collection-preferences";
import {useData} from '../DataContext';
import ReportUploadFileModal from './ReportUploadFileModal';


import {
    Box,
    CollectionPreferences,
    FormField,
    Header,
    Table,
    TableProps,
    SpaceBetween,
    StatusIndicatorProps,
    Badge, Alert, ProgressBar, Textarea
} from '@amzn/awsui-components-react/polaris';
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import * as constants from '../../common/constants';


export interface ReportUploadsTableProps {
    setState: (x: States) => void;
    state: States;
    refreshTable: boolean;
    setRefreshTable: (x: boolean) => void;
}


export default function ReportUploadsTable({
        setState,
        state,
        refreshTable,
        setRefreshTable,
        }: ReportUploadsTableProps) {

    const { report_id }= useParams<{report_id: string}>();
    const [pageSize, setPageSize] = useState(20);
    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: pageSize,
        wrapLines: true,
        visibleContent: ["file_name", "status", "resource_type", "actions"]
    });
    const [allItems, setAllItems] = useState<ReportUpload[]>();
    const [selectedItems, setSelectedItems] = useState<ReportUpload[]>([]);
    const [deleteButtonDisabled, setDeleteButtonDisabled]= useState(true);
    const [validateButtonDisabled, setValidateButtonDisabled]= useState(true);
    const [uploadFileModalVisible, setUploadFileModalVisible]= useState(false);

    const FondueApi = FondueApiFactory();


    let statusMapping = new Map<string,StatusIndicatorProps.Type>([
      ["Validated","success"],
      ["Failed","error"],
      ["Validating","loading"],
      ["Uploaded","in-progress"],
      ["Initiated","loading"]
    ]);

    const statuses = ["Validated","Failed","Validating","Uploaded","Initiated"]

    function updateDeleteButton(items){
        setDeleteButtonDisabled(items.length == 0);
    }

    function updateValidateButton(items){
        let disabled = items.length == 0
        const ableToValidateStatuses = ["Failed"]
        items.forEach(selectedItem =>{
            if(!ableToValidateStatuses.includes(selectedItem.status)){
                disabled = true
            }
        })
        setValidateButtonDisabled(disabled)
    }

    function handleSelect(detail) {
        setSelectedItems(detail.selectedItems);
        updateDeleteButton(detail.selectedItems);
        updateValidateButton(detail.selectedItems);
    }

    function uploadFile(){
        setUploadFileModalVisible(true)
    }

    function downloadReportUpload(upload_name: string){
        const FondueApi = FondueApiFactory();

        retrieveReportUploadFile(FondueApi, upload_name);
    }

    async function retrieveReportUploadFile(FondueApi: FondueApi, upload_name: string) {
        await getMidwayJwtToken();
        await FondueApi.downloadReportUpload(report_id, upload_name)
            .then((response) =>{
                downloadFileS3(response.data.url!);
            })
            // TODO: Give error information to users
    }

    function downloadFileS3(url: string){
        const link = document.createElement('a');

        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    const { items,  filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
            allItems || [],
            {
                filtering: {
                    empty: (
                        <EmptyState
                            title="No records"
                            subtitle="No records to display."
                        />
                    ),
                    noMatch: (
                        <EmptyState
                            title="No matches"
                            subtitle="We can't find a match."
                        />
                    )
                },
                pagination: {
                    pageSize: pageSize
                },
                sorting: { defaultState: { sortingColumn: { sortingField: "upload_name" } } },
                selection: {}
            }
        );

    async function deleteReportUpload(FondueApi: FondueApi, upload_name: string, counter: number){
        await getMidwayJwtToken();
        if(counter < constants.API_RETRY_COUNT){
            await FondueApi.deleteReportUpload(report_id,upload_name)
                .then(async(response) => {
                    //TODO: notify user and refresh table
                })
                .catch((e) => {
                    setState(States.error)
                    deleteReportUpload(FondueApi, upload_name, counter + 1)
                })
        }
    }

    function deleteUpload(){
        selectedItems.forEach(function(item){
           if(item.upload_name){
               // Call deleteReportUpload which will retry up to 3 times
               deleteReportUpload(FondueApi,item.upload_name, 0)
           }
        });
    }

    async function validateReportUpload(upload_name: string, resource_type:string, counter: number){
            await getMidwayJwtToken();
            if(counter < constants.API_RETRY_COUNT){

                let ret_val = 'not set'
                await FondueApi.validateReportUpload(report_id,upload_name,resource_type)
                    .then(async(response) => {
                        ret_val = response['message'];
                    })
                    .catch((e) => {
                        validateReportUpload(upload_name, resource_type, counter + 1).then((response)=>{
                            ret_val=response
                        });
                    })
                return ret_val
            }
            return 'failed'
        }


    function validateUpload(){
        selectedItems.forEach(function(item){
           if(item.upload_name){
               // Call validateReportUpload which will retry up to 3 times
               validateReportUpload(item.upload_name, item.resource_type, 0)
           }
        });
    }

    async function listReportUploads(FondueApi: FondueApi, counter: number) {
        await getMidwayJwtToken();
        if(counter < constants.API_RETRY_COUNT){
            await FondueApi.listReportUploads(report_id)
                .then(async(response) => {
                    setSelectedItems([]);
                    if(response.status === 200){
                        // Filter out unwanted statuses before setting them to table
                        setAllItems(response.data['ReportUploads'].filter(upload => statuses.includes(upload.status)));
                        setState(States.success)
                    }
                    else{
                        setState(States.error)
                    }
                })
                .catch((e) => {
                    setState(States.error)
                    listReportUploads(FondueApi, counter + 1)
                })
        }
    }
    function refreshReportUploads() {
        setAllItems(undefined);
        setState(States.loading)
        // Call listReportUploads which will retry up to 3 times
        listReportUploads(FondueApi, 0)
    }


    useEffect(() => {
        (async () => {
            const FondueApi = FondueApiFactory();

            // Call listReportUploads which will retry up to 3 times
            await listReportUploads(FondueApi, 0)

            // Setting refreshTable to false so additional updates can be picked up
            setRefreshTable(false);
        })();
    }, [refreshTable, FondueApiFactory]);

    return(
        <ExpandableSection variant="container"
          headerText="Uploaded data files"
          headerCounter={
            allItems &&
            (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
          }
          headerActions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button id="refreshButton" onClick={refreshReportUploads} iconName="refresh"></Button>
              <Button id="deleteButton" onClick={deleteUpload} disabled={deleteButtonDisabled}>Delete</Button>
              <Button id="validateButton" onClick={validateUpload} disabled={validateButtonDisabled}>Validate</Button>
              <Button id="uploadButton" onClick={uploadFile} iconName="upload">Upload</Button>
            </SpaceBetween>
          }
          headerDescription="CSV files used to filter results for report items"
        >
            <Table
                {...collectionProps}
                variant="borderless"
                loading={!allItems && state === States.loading}
                loadingText="Loading uploaded files"
                selectionType="multi"
                selectedItems={selectedItems}
                empty={
                  <SpaceBetween alignItems="center" size="xs">
                    <TextContent> No uploaded data files </TextContent>
                    <Button onClick={uploadFile} iconName="upload">
                      Upload
                    </Button>
                  </SpaceBetween>
                }
                columnDefinitions={[
                    {
                        id: 'file_name',
                        header: 'File name',
                        cell: item => item.upload_name,
                        minWidth: '160px',
                        sortingField: 'file_name'
                    },
                    {
                        id: 'status',
                        cell: item  => (
                          <StatusIndicator
                            type={ statusMapping.has(item.status) ? statusMapping.get(item.status) : 'error' }
                          > {item.status} </StatusIndicator>
                        ),
                        header: 'Status',
                        minWidth: '160px',
                        sortingField: 'name'
                    },
                    {
                        id: 'resource_type',
                        cell: item => item.resource_type,
                        header: 'Resource type',
                        minWidth: '160px',
                        sortingField: 'type'
                    },
                    {
                        id: 'actions',
                        header: 'Actions',
                        cell: item  => (
                          <Button
                            iconName="download"
                            variant="link"
                            disabled={item.status != 'Validated'}
                            onClick={() => downloadReportUpload(item.upload_name)}
                          >
                          Download
                          </Button>
                        ),
                        width: 130,
                        minWidth: 100,
                        isRowHeader: true
                    },
                ]}
                visibleColumns={preferences.visibleContent}
                resizableColumns
                items={items}
                stickyColumns={{ last: 1}}
                onSelectionChange={({ detail }) => handleSelect(detail)}
                pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}
                    onChange={ event  => {

                        paginationProps.onChange(event);
                    }}
                />}
                filter={
                    <TextFilter
                        {...filterProps}
                        countText={getMatchesCountText(filteredItemsCount!)}
                        filteringAriaLabel="Filter report uploads"
                        filteringPlaceholder='Find report uploads'
                    />
                }
                preferences={
                    <CollectionPreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        preferences={preferences}
                        pageSizePreference={{
                            title: "Select page size",
                                options: [
                                { value: 20, label: "20 items" },
                                { value: 50, label: "50 items" },
                                { value: 100, label: "100 items" }
                            ]
                        }}
                        onConfirm={({ detail }) => {
                            setPreferences(detail);
                            setPageSize(detail.pageSize as number);
                        }}
                        visibleContentPreference={{
                            title: "Preferences",
                            options: [
                                {
                                    label: "Available columns",
                                    options: [
                                        { id: "file_name", label: "File Name" },
                                        { id: "status", label: "Status" },
                                        { id: "resource_type", label: "Resource type" },
                                        { id: "actions", label: "Actions", editable: false}
                                    ]
                                }
                            ]
                        }}
                    />
                }
            />
          <ReportUploadFileModal
           setVisible={setUploadFileModalVisible}
           visible={uploadFileModalVisible}
          />
        </ExpandableSection>

    );
}
